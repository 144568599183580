/** @format */

import { useEffect, useState } from 'react';
import Header from './shared/Header';
import OverviewPage from './assignmentOverview/OverviewPage';
import Footer from './shared/Footer';
import Player from './assignmentPlayer/Player';

import assignmentDataLesson1 from "./assets/data/lesson1.json";

import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';

const lessonTOC = 
	{
		name: "Beginning Word Recognition, Decoding, and Spelling",
		chapter: 'Module No. X',
		duedate: "05/05/25",
		time: "37 min",
		pointsEarned:0,
		learnings: [
			{
				item: "TBD"
			}
		],
		assignmentParts: [
			{
				title: "Introduction to Beginning Word Recognition, Decoding, and Spelling",
				time: 10,
				groupType: 'feature',
				assignmentItems: [
					{
						id: 'item1.1',
						name: 'Introduction to Beginning Word Recognition, Decoding, and Spelling',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen1-1',
						status: 'not started'
					},
					{
						id: 'item1.2',
						name: 'Applying Theory to Practice',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen1-2',
						status: 'not started'
					},
					{
						id: 'item1.3',
						name: 'Theoretical Background of Reading Development',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen1-3',
						status: 'not started'
					}
				]
			},
			{
				title: "Adams’ View of the Reading Process",
				time: 11,
				groupType: 'feature',
				assignmentItems: [
					{
						id: 'item2.1',
						name: 'Adams’ View of the Reading Process',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen2-1',
						status: 'not started'
					},
					{
						id: 'item2.2',
						name: 'Mastery Measure 1',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen2-2',
						status: 'not started'
					},
					{
						id: 'item2.3',
						name: 'Mastery Measure 2',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen2-3',
						status: 'not started'
					},
					{
						id: 'item2.4',
						name: 'Mastery Measure 3',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen2-4',
						status: 'not started'
					},
					{
						id: 'item2.5',
						name: 'Mastery Measure 4',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen2-5',
						status: 'not started'
					}
				]
			},
			{
				title: "Ehri’s Phases of Word Recognition Development",
				time: 9,
				groupType: 'feature',
				assignmentItems: [
					{
						id: 'item3.1',
						name: 'Ehri’s Phases of Word Recognition Development',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-1',
						status: 'not started'
					},
					{
						id: 'item3.2',
						name: 'Pre Alphabetic Phase',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-2',
						status: 'not started'
					},
					{
						id: 'item3.3',
						name: 'Partial Alphabetic Phase',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-3',
						status: 'not started'
					},
					{
						id: 'item3.4',
						name: 'Learning Checkpoint',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-4',
						status: 'not started'
					},
					{
						id: 'item3.5',
						name: 'Full Alphabetic Phase',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-5',
						status: 'not started'
					},
					{
						id: 'item3.6',
						name: 'Consolidated Alphabetic Phase',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-6',
						status: 'not started'
					},
					{
						id: 'item3.7',
						name: 'Automatic Phase',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-7',
						status: 'not started'
					},
					{
						id: 'item3.8',
						name: 'Learning Checkpoint',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-8',
						status: 'not started'
					},
					{
						id: 'item3.9',
						name: 'Automatic Alphabetic-Skilled Readers',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen3-9',
						status: 'not started'
					},
					{
						id: 'item3.14',
						name: 'Mastery Measure 5',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-10',
						status: 'not started'
					},
					{
						id: 'item3.10',
						name: 'Mastery Measure 6',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-11',
						status: 'not started'
					},
					{
						id: 'item3.11',
						name: 'Mastery Measure 7',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-12',
						status: 'not started'
					},
					{
						id: 'item3.12',
						name: 'Mastery Measure 8',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-13',
						status: 'not started'
					},
					{
						id: 'item3.13',
						name: 'Mastery Measure 9',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen3-14',
						status: 'not started'
					}
				]
			},
			{
				title: "The Simple View of Reading",
				time: 5,
				groupType: 'feature',
				assignmentItems: [
					{
						id: 'item4.1',
						name: 'The Simple View of Reading',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen4-1',
						status: 'not started'
					},
					{
						id: 'item4.2',
						name: 'Reflection',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen4-2',
						status: 'not started'
					},
					{
						id: 'item4.3',
						name: 'Mastery Measure 10',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen4-3',
						status: 'not started'
					}
				]
			},
			{
				title: "Scarborough’s Reading Rope",
				time: 2,
				groupType: 'feature',
				assignmentItems: [
					{
						id: 'item5.1',
						name: 'Scarborough’s Reading Rope',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-1',
						status: 'not started'
					},
					{
						id: 'item5.2',
						name: 'Teaching Early Decoding, Word Recognition, and Spelling',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-2',
						status: 'not started'
					},
					{
						id: 'item5.3',
						name: 'Phonological Awareness, Phonological Sensitivity, and Phonemic Awareness',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-3',
						status: 'not started'
					},
					{
						id: 'item5.4',
						name: 'Teaching Phonemic Awareness',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-4',
						status: 'not started'
					},
					{
						id: 'item5.5',
						name: 'More Challenging Phonemic Awareness Skills',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-5',
						status: 'not started'
					},
					{
						id: 'item5.6',
						name: 'Teaching Phoneme Segmentation',
						totalPoints: 1,
						pointsEarned:0,
						type: 'reading',
						data: 'screen5-6',
						status: 'not started'
					},
					{
						id: 'item5.7',
						name: 'Mastery Measure 11',
						totalPoints: 1,
						pointsEarned:0,
						type: 'question',
						data: 'screen5-7',
						status: 'not started'
					}
				]
			}
		]
	}



const Wrapper = (props) => {
	const [data, setData] = useState(false);
	const [itemOpen, setItemOpen] = useState(props.type && props.type === 'assignment' ? true : false);
	const [currentItem, setCurrentItem] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [totalSteps, setTotalSteps] = useState(1);
	const [itemsList, setItemsList] = useState([]);
	const [grouped, setGrouped] = useState(true);

	const location = useLocation();
	const navigate = useNavigate();
	const search = location.search;

	useEffect(() => {
		const assignment = new URLSearchParams(search).get('content');
		//const grouped = new URLSearchParams(search).get('grouped');

		if (assignment) {
			if (assignment === 'lesson1') {
				setSelectedTopic(lessonTOC)
			}
		} else {
			setSelectedTopic(lessonTOC)
		}
		
	},[])

	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	});

	const setSelectedTopic = (topic) => {
		let tempList = {...topic};
        setCurrentItem(0);
		// if (topic === 'calculus') {
		// 	tempList = assignmentDataCalculus;
		// 	setCurrentItem(0);
		// } else if (topic === 'calculus') {
		// 	tempList = assignmentDataStats;
		// 	setCurrentItem(0);
		// } else {
		// 	tempList = assignmentDataap;
		// 	setCurrentItem(0);
		// }
		// console.log(tempList.assignmentParts)
		let tempList2 = [];
		tempList.assignmentParts.forEach((element) => {
			element.assignmentItems.forEach((item, i) => {
				tempList2.push(item);
			});
		});
		tempList.itemsCompleted = 0;
		tempList.totalItems = countItemTypes(tempList).total
		tempList.overallscore = 0;
		tempList.totalPoints = countItemTypes(tempList).totalPoints
		tempList.totalTime = countItemTypes(tempList).totalTime
		setData(tempList);
		setItemsList(tempList2);
	};

	const openPlayer = () => {
		setItemOpen(true);
		navigate(`/assignment${search}`)
	};

	const closePlayer = () => {
		setItemOpen(false);
		navigate(`/assignmentOverview${search}`)
	};
	// const navigateToAssgn = () => {
    //     navigate(`/assignment${search}`)
    // }

	//Navigate to next item - Called from the next button
	const nextItem = () => {
		/* Check how many steps */
		if (totalSteps > 1 && currentStep < totalSteps - 1) {
			setCurrentStep(currentStep + 1);
		} else {
			/* Set current item as complete */
			let itemPoints = itemsList[currentItem].totalPoints
				? itemsList[currentItem].totalPoints
				: 0;
			let totalScore = data.overallscore + itemPoints;

			var tempList = [...itemsList];
			if (tempList[currentItem].status !== 'completed') {
				tempList[currentItem].pointsEarned = itemPoints;
				tempList[currentItem].status = 'completed';

				/* Update status of items in the full stack */
				let fullData = data;
				// fullData.assignmentParts.forEach((element) => {
				// 	element.assignmentItems.forEach((e) => {
				// 		if (e.itemid === itemsList[currentItem].itemId) {
				// 			e.pointsEarned = itemPoints;
				// 			// e.status = 'completed';
				// 		}
				// 	});
				// });

				/* Update total score and items completed */
				fullData.itemsCompleted = data.itemsCompleted + 1;
				fullData.overallscore = totalScore;

				setData(fullData);
				setItemsList(tempList);

				/* Check if the locked item needs to be unlocked */
				// checkToUnlock(7);
			}

			if (currentItem < tempList.length - 1) {
				/* Check if question has multiple parts ---*/
				// if (
				// 	tempList[currentItem + 1].type === 'question' &&
				// 	typeof tempList[currentItem + 1].content[0].answer === 'object'
				// ) {
				// 	setTotalSteps(tempList[currentItem + 1].content[0].answer.length);
				// 	setCurrentStep(0);
				// }

				/* Check if item is still locked ---*/
				if (tempList[currentItem + 1].locked) {
					setCurrentItem(currentItem + 2);
				} else {
					setCurrentItem(currentItem + 1);
				}
			} else {
				setItemOpen(false);
			}

			document.getElementById('assignmentContent').scrollTo(0, 0);
		}
	};

	const prevItem = (num) => {
		gotoItem(num)
	}


	//Navigate to a specfic item. Called from cards.
	const gotoItem = (itemNum) => {
		if (!itemOpen) {
			setItemOpen(true);
		}
		setCurrentItem(itemNum);

		console.log(itemNum)

		/* Check if the locked item needs to be unlocked */
		// checkToUnlock(7);
	};
	const countItemTypes = (source) => {
        let count = {reading:0, video:0,activity:0,question:0, other:0, total: 0, totalPoints:0, totalTime:0}

        source.assignmentParts.forEach(part => {
            part.assignmentItems.forEach(item => {
                count.total = count.total + 1
                count.totalPoints = count.totalPoints + item.totalPoints
                count.totalTime = count.totalTime + +item.time //in seconds
                
				// if (item.type === 'reading') {
                //     count.reading = count.reading + 1
                    
                // } else if (item.type === 'video') {
                //     count.video = count.video + 1
                // } else if (item.type === 'activity') {
                //     count.activity = count.activity + 1
                // } else if (item.type === 'question') {
                //     count.question = count.question + 1
                // } else {
                //     count.other = count.other + 1
                // }
            })
        })

        return count
    }
	// useEffect(() => {
    //     setSelectedTopic('aandp');
	// 	// if (location.pathname.indexOf('a&p') > -1) {
	// 	// 	setSelectedTopic('aandp');
	// 	// }
	// },[]);

	if (data) {return (
		<>
			
			{itemOpen ? (
				<Player
					closePlayer={closePlayer}
					data={data}
					currentItem={currentItem}
					nextItem={nextItem}
					prevItem={prevItem}
					itemsList={itemsList}
					gotoItem={gotoItem}
					itemsCompleted={data.itemsCompleted}
					totalItems={data.totalItems}
					currentStep={currentStep}
					totalSteps={totalSteps}
					scroll={props.scroll}
				/>
			) : (
				<>
				<Header
					itemOpen={itemOpen}
					ref={ref}
					page={itemOpen ? "player" : "overview"}
					title={itemOpen ? data.chapter : "The Science of Teaching Reading"}
				/>
					<OverviewPage
						data={data}
						setData={setData}
						openPlayer={openPlayer}
						gotoItem={gotoItem}
						inView={inView}
						itemsList={itemsList}
						currentItem={currentItem}
						grouped={grouped}
					/>
					{/* <Footer /> */}
				</>
			)}
		</>
	) } else {
        return (
            <div>loading...</div>
        )
    }
}

export default Wrapper;
